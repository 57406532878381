import React, { useState, useEffect } from "react";

// https://chat.openai.com/share/0d0569f6-1b40-4952-95c7-5ca91478efc0

function PokemonList() {
  const [pokemons, setPokemons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchPokemons() {
      try {
        // Create an array of promises to fetch data in batches of 10
        // const requests = Array.from({ length: 100 }, (_, i) =>

        const requests = Array.from({ length: 10 }, (_, i) =>
          fetch(`https://pokeapi.co/api/v2/pokemon?offset=${i * 10}&limit=10`)
        );

        // Wait for all fetch requests to complete
        const responsePromises = await Promise.all(requests);

        // Parse the JSON responses
        const responseData = await Promise.all(
          responsePromises.map((res) => res.json())
        );

        // Flatten the array and set the pokemons in state
        setPokemons(responseData.flatMap((data) => data.results));
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
        setLoading(false);
      }
    }

    fetchPokemons();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
          <h3>Pokémons:</h3>
          <ul style={{ textAlign: "left" }}>
            {pokemons.map((pokemon, index) => (
              <li key={index}>
                <a
                  href={pokemon.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  {pokemon.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PokemonList;
